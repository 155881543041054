<template>
  <v-container fluid>
    <AError :api="apiUpdateAttachment" />

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar :color="HRDC_BLUE_THEME_COLOR">
        <v-btn icon class="hidden-xs-only text-white" @click="redirectBack()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title v-if="!loadingDialog">
          <div class="d-flex align-center text-white">
            {{ model.name.plural }}
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <span v-if="isHrdcSuperAdminRoles">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="white"
                class="hidden-xs-only"
                @click="toggleUpdateAtchDialog(true)"
              >
                <v-icon dark v-bind="attrs" v-on="on"> mdi-upload </v-icon>
              </v-btn>
            </template>
            <span>Update {{ model.name.plural }}</span>
          </v-tooltip>
        </span>
      </v-toolbar>

      <div class="pa-3">
        <UploadAttachmentSettingFileSizeAlert></UploadAttachmentSettingFileSizeAlert>

        <v-row>
          <v-col cols="6" sm="6" md="6">
            <div
              v-cloak
              @drop.prevent="addDropFile($event, 'guideline', true)"
              @dragover.prevent
              class="w-100"
            >
              <v-file-input
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                dense
                filled
                small-chips
                label="Guildeline"
                accept=".pdf"
                v-model="formData.guideline"
                :error-messages="errorField.guideline"
                :truncate-length="1000"
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="6" class="font-weight-bold">
            <v-btn
              x-large
              :color="HRDC_BLUE_THEME_COLOR"
              outlined
              @click="previewAttachment('guideline')"
            >
              Preview
            </v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6" sm="6" md="6" class="py-0">
            <div
              v-cloak
              @drop.prevent="addDropFile($event, 'agenda', true)"
              @dragover.prevent
              class="w-100"
            >
              <v-file-input
                :prepend-icon="null"
                prepend-inner-icon="mdi-paperclip"
                dense
                filled
                small-chips
                label="Course Content (Agenda)"
                accept=".pdf"
                v-model="formData.agenda"
                :error-messages="errorField.agenda"
                :truncate-length="1000"
              ></v-file-input>
            </div>
          </v-col>
          <v-col cols="2" class="font-weight-bold py-0">
            <v-btn
              x-large
              :color="HRDC_BLUE_THEME_COLOR"
              outlined
              @click="previewAttachment('agenda')"
            >
              Preview
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-card>

    <TheModalConfirmation
      ref="updateAtchConfirmPopup"
      @agree="agreeCallbacUpdateAtch()"
      @disagree="disagreeCallbacUpdateAtch()"
    ></TheModalConfirmation>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import Model from "@/objects/model";
import Service from "@/objects/service";
import TheModalConfirmation from "@/components/common/TheModalConfirmation.vue";
import UploadAttachmentSettingFileSizeAlert from "@/components/views/UploadAttachmentSettingFileSizeAlert.vue";

export default {
  components: {
    TheModalConfirmation,
    UploadAttachmentSettingFileSizeAlert,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiUpdateAttachment: new Api(),
    apiGetAttachmentBlob: new Api(),
    model: new Model(),
    service: new Service(),
    alertError: [],
    errorLogDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    formData: {
      guideline: null,
      agenda: null,
    },
    errorField: {
      guideline: null,
      agenda: null,
    },
    staticAttachments: [],
    loadingDialog: false,
  }),
  async created() {
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);
    this.serviceDataAssignation(this.service.key);

    const apiDetails = {
      apiGetAttachmentBlob: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/application/get-attachment-blob`,
      },
      apiUpdateAttachment: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/attachment/update-attachment`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].query !== undefined) {
        this[api].setQuery(apiDetails[api].query);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    // Assign fake pdf data.
    this.formData = {
      guideline: { name: `${this.guidelineName}.pdf` },
      agenda: { name: `${this.agendaAtchName}.pdf` },
    };

    this.rolesAssignation(this.auth.roles);
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    redirectBack() {
      let route = this.model.parent_route;
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    addDropFile(e, key, strictPdf = true) {
      let file = e.dataTransfer.files[0];

      if (strictPdf) {
        if (!file.type.match("pdf.*")) {
          this.errorField[key] = "Please select pdf file format";
          return;
        }
      }

      this.formData[key] = file;
    },
    previewAttachment(fileType) {
      // Check is empty file.
      if (!this.formData[fileType]) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
          timeout: 2000,
        });
        return false;
      }

      this.showLoadingDialog();

      const fileUrl = `assets/storage/${this.formData[fileType].name}`;

      this.$axios
        .get(this.apiGetAttachmentBlob.url, {
          responseType: "blob",
          headers: {
            "Content-Type": "application/json",
          },
          params: {
            filePath: fileUrl,
          },
        })
        .then((response) => {
          const blobUrl = URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );

          window.open(blobUrl, "_blank");
        })
        .catch(() => {
          this.previewBlobAttachment(fileType);
        })
        .finally(() => {
          this.hideLoadingDialog();
        });
    },
    async previewBlobAttachment(fileType) {
      this.showLoadingDialog();

      let fileUrl = this.formData[fileType];

      if (!fileUrl) {
        this.$store.dispatch("showMessage", {
          message: "No Preview Available",
          messageType: "info",
        });
        return;
      }

      let url = null;
      let newWindow = null;
      switch (fileType) {
        default:
          url = URL.createObjectURL(fileUrl);
          newWindow = window.open(url, "_blank");
          break;
      }

      if (newWindow) {
        newWindow.onload = function () {
          // Revoke the object URL when the window is closed
          newWindow.addEventListener("beforeunload", function () {
            URL.revokeObjectURL(url);
          });
        };
      }
    },
    getOriFileName(file) {
      let fileName = null;

      switch (file) {
        case "guideline":
          fileName = `${this.guidelineName}.pdf`;
          break;

        case "agenda":
          fileName = `${this.agendaAtchName}.pdf`;
          break;
      }

      return fileName;
    },
    toggleUpdateAtchDialog(val) {
      if (val) {
        this.$refs.updateAtchConfirmPopup.open({
          title: "Update Attachment",
          message: "Are you confirm to update attachment?",
          options: {
            btnDisagree: "Cancel",
            btnAgree: "Update",
          },
          agree: () => {
            this.agreeCallbacUpdateAtch();
          },
          disagree: () => {
            this.disagreeCallbacUpdateAtch();
          },
        });
      }
    },
    agreeCallbacUpdateAtch() {
      this.showLoadingDialog();
      this.clearErrorMsg();

      // Check is file fields empty.
      let isUploadContainsEmpty = false;
      Object.keys(this.formData).forEach((field) => {
        if (!this.formData[field]) {
          this.errorField[field] = `${this.capitalizeFirstLetter(
            field
          )} is required`;
          isUploadContainsEmpty = true;
        }
      });

      if (isUploadContainsEmpty) {
        this.hideLoadingDialog();
        return false;
      }

      const fd = new FormData();

      // Only append File instance.
      for (const atch in this.formData) {
        if (this.formData[atch] instanceof File) {
          fd.append(
            `${atch}_filepath`,
            `assets/storage/${this.getOriFileName(atch)}`
          );
          fd.append(atch, this.formData[atch]);
        }
      }

      const fdLength = [...fd.entries()].length;
      if (fdLength == 0) {
        this.$store.dispatch("showMessage", {
          message: "No changes apply!",
          messageType: "info",
          timeout: 2000,
        });

        this.hideLoadingDialog();
        return;
      }

      // Only append static datas after checking.
      fd.append("staticFiles", this.staticEventDocs);

      this.apiUpdateAttachment.setParams(fd);
      this.apiUpdateAttachment.setCallbackCompleted((response) => {
        try {
          const { status, data } = response;

          let messageType = "success";
          if (!status) {
            messageType = "errror";
          }

          this.$store.dispatch("showMessage", {
            message: data,
            messageType,
            timeout: 2000,
          });
        } catch (err) {
          console.log(err);
        } finally {
          this.hideLoadingDialog();
        }
      });
      this.apiUpdateAttachment.fetch();
    },
    disagreeCallbacUpdateAtch() {
      //
    },
  },
};
</script>
